<template>
  <div class="odontograma text-center">

    <div class="odontograma-sup">
      <div class="odontogram-number">
        <b-button
            :variant="superior === true ? 'gradient-success' : 'secondary'"
            @click="superior = !superior, selecionouTodos('SUP')"
            size="sm"
            :style="`padding: 4px;position: relative;top: 82px;left: ${espacoSupInf}px;` ">
          {{ $t('SUP') }}
        </b-button>
      </div>

      <div
          v-for="(dente, index) in superiores[indexComponente]"
          :key="index"
          class="odontograma-dente sup"
      >
        <div class="dente-container">
          <img
              @click="alteraDente('SUP', dente)"
              :src="dente.selected ? `/images/dentes/${dente.imageSelected}` : `/images/dentes/${dente.image}`"
              :alt="dente.name"
              :width="dente.size"
          />
          <div class="dente-numero">{{ dente.number }}</div>
        </div>
      </div>
    </div>
    <div class="odontograma-inf">
      <div class="odontogram-number">
        <b-button
            :variant="inferior === true ? 'gradient-success' : 'secondary'"
            @click="inferior = !inferior, selecionouTodos('INF')"
            size="sm"
            :style="`padding: 4px;position: relative;top: 25px;left: ${espacoSupInf}px;` ">
          {{ $t('INF') }}
        </b-button>
      </div>
      <div
          v-for="(dente, index) in inferiores[indexComponente]"
          :key="index"
          class="odontograma-dente inf"
      >
        <div class="dente-container">
          <div class="dente-numero">{{ dente.number }}</div>
          <img
              @click="alteraDente('INF', dente)"
              :src="dente.selected ?  `/images/dentes/${dente.imageSelected}` : `/images/dentes/${dente.image}`"
              :alt="dente.name"
              :width="dente.size"
          />

        </div>
      </div>
    </div>
    <span class="float-left mb-2 text-primary"> <strong> {{ $t('Dentes_Selecionados') }} </strong> {{ dentesSelecionados.toString() }}</span>

  </div>
</template>


<script>
import {BButton} from "bootstrap-vue";
import {mapGetters, mapState} from "vuex";
import * as dentesUS from '@/views/pedidos/odontograma/dentesUS';
import * as dentesDefault from '@/views/pedidos/odontograma/dentesDefault';
import OdontogramaMixins from "@/views/pedidos/odontograma/OdontogramaMixins";
import cloneDeep from 'lodash/cloneDeep';


export default {
  components: {BButton},
  props: ['dentesSelecionados', 'indexComponente'],
  mixins: [OdontogramaMixins],
  data() {
    return {
      espacoSupInf: -240,
      superior: false,
      inferior: false,
      arraySuperiores: [],
      arrayInferiores: [],
      superiores: [],
      inferiores: []
    }
  },

  computed: {
    ...mapState({
      odontograma: state => state.pedidos.odontograma
    }),
    ...mapGetters({
      getPais: 'laboratorios/getPais'
    })
  },
  mounted() {
    this.carregarConfiguracaoDentes();
  },
  watch: {
    getPais: {
      immediate: true,
      handler(newValue) {
        console.log('Watch getPais, novo valor:', newValue);
        if (newValue) {
          this.carregarConfiguracaoDentes();
        }
      }
    }

  },


  methods: {

    carregarConfiguracaoDentes() {

      if (this.getPais === 'US') {
        this.arraySuperiores = dentesUS.arraySuperiores;
        this.arrayInferiores = dentesUS.arrayInferiores;
        this.$set(this.superiores, this.indexComponente, cloneDeep(dentesUS.superiores));
        this.$set(this.inferiores, this.indexComponente, cloneDeep(dentesUS.inferiores));

      } else {
        this.arraySuperiores = dentesDefault.arraySuperiores;
        this.arrayInferiores = dentesDefault.arrayInferiores;
        this.$set(this.superiores, this.indexComponente, cloneDeep(dentesDefault.superiores));
        this.$set(this.inferiores, this.indexComponente, cloneDeep(dentesDefault.inferiores));

      }

    },

  }
}
</script>

<style scoped>
.odontograma {
  width: 500px;
  margin: 0 auto;
  margin-bottom: 5px;
  margin-top: 5px;
}

.odontograma-sup {

  border-bottom: dashed 1px #c5c5c5;
}

.odontograma-inf {

  margin-top: -22px;
}

.odontograma-dente {
  display: inline-block;
  margin: 3px; /* Espaçamento entre os dentes */
}

.dente-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dente-numero {
  margin-top: 2px; /* Espaçamento entre a imagem e o número */
  font-size: 14px; /* Tamanho da fonte do número */
  color: #333; /* Cor do texto */
  text-align: center;
  cursor: pointer;
}

.odontograma-dente.inf {
  /* Estilos específicos para a parte inferior, se necessário */
}
</style>