export const arraySuperiores = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16'];
export const arrayInferiores = ['32', '31', '39', '29', '28', '27', '26', '25', '24', '23', '22', '21', '20', '19', '18', '17'];

export const superiores = [
    {
        image: 'dente-18.png',
        imageSelected: 'dente-18-selecionado.png',
        name: '1',
        number: '1',
        size: 24,
        selected: false        },
    {
        image: 'dente-17.png',
        imageSelected: 'dente-17-selecionado.png',
        name: '2',
        number: '2',
        size: 26,
        selected: false
    },
    {
        image: 'dente-16.png',
        imageSelected: 'dente-16-selecionado.png',
        name: '3',
        number: '3',
        size: 28,
        selected: false
    },
    {
        image: 'dente-15.png',
        imageSelected: 'dente-15-selecionado.png',
        name: '4',
        number: '4',
        size: 18,
        selected: false
    },
    {
        image: 'dente-14.png',
        imageSelected: 'dente-14-selecionado.png',
        name: '5',
        number: '5',
        size: 18,
        selected: false
    },
    {
        image: 'dente-13.png',
        imageSelected: 'dente-13-selecionado.png',
        name: '6',
        number: '6',
        size: 18,
        selected: false
    },
    {
        image: 'dente-12.png',
        imageSelected: 'dente-12-selecionado.png',
        name: '7',
        number: '7',
        size: 18,
        selected: false
    },
    {
        image: 'dente-11.png',
        imageSelected: 'dente-11-selecionado.png',
        name: '8',
        number: '8',
        size: 20,
        selected: false
    },
    {
        image: 'dente-21.png',
        imageSelected: 'dente-21-selecionado.png',
        name: '9',
        number: '9',
        size: 20,
        selected: false
    },
    {
        image: 'dente-22.png',
        imageSelected: 'dente-22-selecionado.png',
        name: '10',
        number: '10',
        size: 18,
        selected: false
    },
    {
        image: 'dente-23.png',
        imageSelected: 'dente-23-selecionado.png',
        name: '11',
        number: '11',
        size: 18,
        selected: false
    },
    {
        image: 'dente-24.png',
        imageSelected: 'dente-24-selecionado.png',
        name: '12',
        number: '12',
        size: 18,
        selected: false
    },
    {
        image: 'dente-25.png',
        imageSelected: 'dente-25-selecionado.png',
        name: '13',
        number: '13',
        size: 20,
        selected: false
    },
    {
        image: 'dente-26.png',
        imageSelected: 'dente-26-selecionado.png',
        name: '14',
        number: '14',
        size: 24,
        selected: false
    },
    {
        image: 'dente-27.png',
        imageSelected: 'dente-27-selecionado.png',
        name: '15',
        number: '15',
        size: 28,
        selected: false
    },
    {
        image: 'dente-28.png',
        imageSelected: 'dente-28-selecionado.png',
        name: '16',
        number: '16',
        size: 25,
        selected: false
    },
];

export const inferiores = [
    {
        image: 'dente-48.png',
        imageSelected: 'dente-48-selecionado.png',
        name: '32',
        number: '32',
        size: 28,
        selected: false
    },
    {
        image: 'dente-47.png',
        imageSelected: 'dente-47-selecionado.png',
        name: '31',
        number: '31',
        size: 25,
        selected: false
    },
    {
        image: 'dente-46.png',
        imageSelected: 'dente-46-selecionado.png',
        name: '30',
        number: '30',
        size: 28,
        selected: false
    },
    {
        image: 'dente-45.png',
        imageSelected: 'dente-45-selecionado.png',
        name: '29',
        number: '29',
        size: 20,
        selected: false
    },
    {
        image: 'dente-44.png',
        imageSelected: 'dente-44-selecionado.png',
        name: '28',
        number: '28',
        size: 20,
        selected: false
    },
    {
        image: 'dente-43.png',
        imageSelected: 'dente-43-selecionado.png',
        name: '27',
        number: '27',
        size: 20,
        selected: false
    },
    {
        image: 'dente-42.png',
        imageSelected: 'dente-42-selecionado.png',
        name: '26',
        number: '26',
        size: 16,
        selected: false
    },
    {
        image: 'dente-41.png',
        imageSelected: 'dente-41-selecionado.png',
        name: '25',
        number: '25',
        size: 15,
        selected: false
    },

    {
        image: 'dente-31.png',
        imageSelected: 'dente-31-selecionado.png',
        name: '24',
        number: '24',
        size: 15,
        selected: false
    },
    {
        image: 'dente-32.png',
        imageSelected: 'dente-32-selecionado.png',
        name: '23',
        number: '23',
        size: 16,
        selected: false
    },
    {
        image: 'dente-33.png',
        imageSelected: 'dente-33-selecionado.png',
        name: '22',
        number: '22',
        size: 19,
        selected: false
    },
    {
        image: 'dente-34.png',
        imageSelected: 'dente-34-selecionado.png',
        name: '21',
        number: '21',
        size: 19,
        selected: false
    },
    {
        image: 'dente-35.png',
        imageSelected: 'dente-35-selecionado.png',
        name: '20',
        number: '20',
        size: 20,
        selected: false
    },
    {
        image: 'dente-36.png',
        imageSelected: 'dente-36-selecionado.png',
        name: '19',
        number: '19',
        size: 28,
        selected: false
    },
    {
        image: 'dente-37.png',
        imageSelected: 'dente-37-selecionado.png',
        name: '18',
        number: '18',
        size: 27,
        selected: false
    },
    {
        image: 'dente-38.png',
        imageSelected: 'dente-38-selecionado.png',
        name: '17',
        number: '17',
        size: 28,
        selected: false
    },
];
