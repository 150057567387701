

export default {

    methods: {
        gerenciarGrupo(grupo, tag) {

            let novosDentesSelecionados = [...this.dentesSelecionados];

            const dentesDoGrupo = grupo === 'SUP' ? this.superiores[this.indexComponente] : this.inferiores[this.indexComponente];
            const todosSelecionados = dentesDoGrupo.every(dente => dente.selected);


            if (todosSelecionados) {
                if (!novosDentesSelecionados.includes(tag)) {
                    novosDentesSelecionados.unshift(tag);
                }
                novosDentesSelecionados = novosDentesSelecionados.filter(dente => !this[`array${grupo === 'SUP' ? 'Superiores' : 'Inferiores'}`].includes(dente));
            } else {
                const indexTag = novosDentesSelecionados.indexOf(tag);
                if (indexTag !== -1) {
                    novosDentesSelecionados.splice(indexTag, 1);
                }
            }
        },
        alteraDente(supOuINf, dente, index) {



            if(supOuINf === 'INF' && this.inferior[this.indexComponente]){
                return
            }

            if(supOuINf === 'SUP' && this.superior[this.indexComponente]){
                return
            }

            let novosDentesSelecionados = [...this.dentesSelecionados];
            dente.selected = !dente.selected;

            if (dente.selected) {
                if (!novosDentesSelecionados.includes(dente.number)) {
                    novosDentesSelecionados.push(dente.number);
                }
            } else {
                const index = novosDentesSelecionados.indexOf(dente.number);
                if (index !== -1) {
                    novosDentesSelecionados.splice(index, 1);
                }
            }

            // Identifica o grupo do dente
            const grupo = this.superiores[this.indexComponente].some(d => d.number === dente.number) ? 'SUP' :
                this.inferiores[this.indexComponente].some(d => d.number === dente.number) ? 'INF' : null;

            if (grupo) {
                this.gerenciarGrupo(grupo, grupo);
            }

            this.atualizaComponente(novosDentesSelecionados);
        },
        selecionouTodos(grupo) {
            const isSuperior = grupo === 'SUP';
            const tag = isSuperior ? 'SUP' : 'INF';
            const dentes = isSuperior ? this.superiores[this.indexComponente] : this.inferiores[this.indexComponente];
            const arrayGrupo = isSuperior ? this.arraySuperiores : this.arrayInferiores;

            let novosDentesSelecionados = [...this.dentesSelecionados];
            dentes.forEach(dente => {
                dente.selected = this[isSuperior ? 'superior' : 'inferior'];
            });

            if (this[isSuperior ? 'superior' : 'inferior']) {
                if (!novosDentesSelecionados.includes(tag)) {
                    novosDentesSelecionados.unshift(tag);
                }
                novosDentesSelecionados = novosDentesSelecionados.filter(dente => !arrayGrupo.includes(dente));
            } else {
                const indexTag = novosDentesSelecionados.indexOf(tag);
                if (indexTag !== -1) {
                    novosDentesSelecionados.splice(indexTag, 1);
                }
            }

            this.atualizaComponente(novosDentesSelecionados);
        },
        desativaTodos() {


            console.log('entrou aqui desativa todos', this.indexComponente);
            this.superiores[this.indexComponente].forEach(dente => {
                dente.selected = false;
            });

            this.inferiores[this.indexComponente].forEach(dente => {
                dente.selected = false;
            });

            this.superior = false;
            this.inferior = false;

            this.atualizaComponente();

        },
        atualizaComponente(dentesSelecionados = []) {
            this.$emit('denteSelecionado', dentesSelecionados);
        }
    }


}