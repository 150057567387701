export const arraySuperiores = ['55', '54', '53', '52', '51', '61', '62', '63', '64', '65'];
export const arrayInferiores = ['85', '84', '83', '82', '81', '71', '72', '73', '74', '75']

export const superiores = [
    {
        image: 'dente-17.png',
        imageSelected: 'dente-17-selecionado.png',
        name: '55',
        number: '55',
        size: 26,
        selected: false        },
    {
        image: 'dente-16.png',
        imageSelected: 'dente-16-selecionado.png',
        name: '54',
        number: '54',
        size: 28,
        selected: false
    },
    {
        image: 'dente-15.png',
        imageSelected: 'dente-15-selecionado.png',
        name: '53',
        number: '53',
        size: 18,
        selected: false
    },
    {
        image: 'dente-12.png',
        imageSelected: 'dente-12-selecionado.png',
        name: '52',
        number: '52',
        size: 18,
        selected: false
    },
    {
        image: 'dente-11.png',
        imageSelected: 'dente-11-selecionado.png',
        name: '51',
        number: '51',
        size: 20,
        selected: false
    },
    {
        image: 'dente-21.png',
        imageSelected: 'dente-21-selecionado.png',
        name: '61',
        number: '61',
        size: 20,
        selected: false
    },
    {
        image: 'dente-22.png',
        imageSelected: 'dente-22-selecionado.png',
        name: '62',
        number: '62',
        size: 18,
        selected: false
    },
    {
        image: 'dente-25.png',
        imageSelected: 'dente-25-selecionado.png',
        name: '63',
        number: '63',
        size: 20,
        selected: false
    },
    {
        image: 'dente-26.png',
        imageSelected: 'dente-26-selecionado.png',
        name: '64',
        number: '64',
        size: 24,
        selected: false
    },
    {
        image: 'dente-27.png',
        imageSelected: 'dente-27-selecionado.png',
        name: '65',
        number: '65',
        size: 28,
        selected: false
    },
];

export const inferiores = [
    {
        image: 'dente-47.png',
        imageSelected: 'dente-47-selecionado.png',
        name: '85',
        number: '85',
        size: 25,
        selected: false
    },
    {
        image: 'dente-46.png',
        imageSelected: 'dente-46-selecionado.png',
        name: '84',
        number: '84',
        size: 28,
        selected: false
    },
    {
        image: 'dente-45.png',
        imageSelected: 'dente-45-selecionado.png',
        name: '83',
        number: '83',
        size: 20,
        selected: false
    },
    {
        image: 'dente-42.png',
        imageSelected: 'dente-42-selecionado.png',
        name: '82',
        number: '82',
        size: 16,
        selected: false
    },
    {
        image: 'dente-41.png',
        imageSelected: 'dente-41-selecionado.png',
        name: '81',
        number: '81',
        size: 15,
        selected: false
    },
    {
        image: 'dente-31.png',
        imageSelected: 'dente-31-selecionado.png',
        name: '71',
        number: '71',
        size: 15,
        selected: false
    },
    {
        image: 'dente-32.png',
        imageSelected: 'dente-32-selecionado.png',
        name: '72',
        number: '72',
        size: 16,
        selected: false
    },
    {
        image: 'dente-35.png',
        imageSelected: 'dente-35-selecionado.png',
        name: '73',
        number: '73',
        size: 20,
        selected: false
    },

    {
        image: 'dente-36.png',
        imageSelected: 'dente-36-selecionado.png',
        name: '74',
        number: '74',
        size: 28,
        selected: false
    },
    {
        image: 'dente-37.png',
        imageSelected: 'dente-37-selecionado.png',
        name: '75',
        number: '75',
        size: 27,
        selected: false
    },
];
