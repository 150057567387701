<template>
  <b-modal
      id="modalLancarPedido"
      hide-footer
      centered
      size="md"
      :title="$t('Lancar_Pedido')"
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
  >

    <b-card v-if="getErrors.hasMessage">
      <b-row>
        <b-col cols="12">
          <b-alert :variant="getErrors.color"  show>
            <div class="alert-body" v-for="(error, index) in getErrors.messageArray" :key="index">
              {{error[0]}}
            </div>
            <div class="alert-body">{{getErrors.message}}</div>
          </b-alert>
        </b-col>
      </b-row>
    </b-card>

    <b-card v-if="pedidoId">
      <b-row>
        <b-col cols="12">
          <b-alert variant="success" show>
            <div class="alert-body">{{ $t('Pedido_Numero') }} {{ pedidoId }} {{ $t('Gerado_Sucesso') }}</div>
          </b-alert>
        </b-col>
      </b-row>
    </b-card>

    <b-card v-if="files.length" :title="$t('Upload_Arquivos')">
      <div class="demo-vertical-spacing" v-for="file in files">
        <div class="progress-wrapper mb-1">
          <b-card-text class="mb-0">
            <strong class="text-primary"> {{ file.name }} {{ uploadProgress[file.name] +'%' }} </strong>
            <strong class="float-right text-primary"> {{ file.size | convertToMb }} MB </strong>
          </b-card-text>
          <b-progress
              v-model.sync="uploadProgress[file.name]"
              max="100"
              :variant="uploadProgress[file.name] < 100 ? 'primary' : 'success' "
          />
        </div>
      </div>
    </b-card>

    <b-card :title="$t('Upload_Arquivos')" v-else>
      <span>{{ $t('Nenhum_Arquivo_Enviado') }}</span>
    </b-card>

    <b-card v-if="errorUploadFiles.length" :title="$t('Falha_Upload_Arquivos')">
      <div class="demo-vertical-spacing" v-for="fileError in errorUploadFiles">
        <div class="progress-wrapper mb-1">
          <b-card-text class="mb-0">
            <strong class="text-danger"> {{ fileError }} </strong>
          </b-card-text>
        </div>
      </div>
    </b-card>

    <b-row class="mt-2">
      <b-col>
        <!-- Button: IMPRIMIR PEDIDO -->
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            :disabled="submit"
            @click="imprimir"
        >
          <feather-icon icon="PrinterIcon"></feather-icon> {{ $t('Imprimir_Pedido') }}
        </b-button>
      </b-col>
      <b-col>
        <b-button
            variant="outline-secondary"
            block
            :disabled="submit"
            @click="fecharModal"
        >
          {{ $t('Fechar') }}
        </b-button>
      </b-col>

    </b-row>

    <ImprimirPedidoOs :pedido="pedidoOs" v-show="false"/>
  </b-modal>
</template>

<script>

import {
  BAlert,
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BModal,
  BRow,
  BDropdown,
  BDropdownItem, BCardText, BProgress,

} from 'bootstrap-vue'

import Cleave from "vue-cleave-component";
import vSelect from "vue-select";
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from "axios";
import ImprimirPedidoOs from "@/views/pedidos/ImprimirPedidoOs.vue";

export default {

  props: ['files', 'uploadProgress', 'pedidoId','laboratorios'],
  components:{
    BProgress,
    BCardText,
    BBadge,
    BCard,
    BAlert,
    BModal,
    BRow,
    BCol,
    BButton,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormGroup,
    BFormDatepicker,
    Cleave,
    vSelect,
    BDropdown,
    BDropdownItem,
    ToastificationContent,
    ImprimirPedidoOs
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      getErrors: {
        hasMessage: false,
        color: "danger",
        message: "",
        messageArray: "",
      },
      progressArr: [],
      errorUploadFiles: [],
      pedidoOs: [],
      submit: false
    }
  },

  methods: {

    fecharModal() {
      this.$emit('fechouModal')
      this.$bvModal.hide('modalLancarPedido')
      
      if (this.$route.params.id > 0) {
        
        this.$router.push({ name: 'pedidos' })
        
      }
    },
    getUniqueFileName(file) {
      const parts = file.name.split('.');
      const extension = parts.pop();
      const fileName = parts.join('.');
      const randomString = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

      return {
        nome: `${fileName}-${randomString}.${extension}`,
        ext: extension
      }
    },

    getType(ext) {
      const imageExtensions = ['png', 'jpg', 'jpeg', 'bmp'];

      if(ext && imageExtensions.includes(ext)) {
        return 1;
      }

      return 2;
    },

    async handleFilesUpload() {

      this.submit = true
      const files = this.files;
      const s3AxiosInstance = axios.create();

      for (let i = 0; i < files.length; i++) {

        const uniqueFileName = this.getUniqueFileName(files[i]);

        const presignedData = await this.$http.get(`https://smartprotese.app/api/v1/odontodocs/pedidos/uploads/get_url?nome=${uniqueFileName.nome}`);
        const presignedUrl = presignedData.data;

        await s3AxiosInstance.put(presignedUrl, files[i], {
          headers: {
            "Content-Type": files[i].type
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            this.$set(this.uploadProgress, files[i].name, percentCompleted);
          }
        });

        let fileStore = {
          url: uniqueFileName.nome,
          nome: files[i].name,
          tamanho: (files[i].size / 1048576).toFixed(2),
          tipo: this.getType(uniqueFileName.ext),
        }

        this.filesStore(fileStore)
      }

      await this.$emit('uploadCompleted')
      this.submit = false
    },

    filesStore(file) {
      this.$http.post(`odontodocs/pedidos/uploads/${this.pedidoId}/${this.laboratorios}`, file).then( (res) => {

        // fazer lógica mostrar que upload foi concluido

      }).catch( error => {
        console.log(error)
        this.errorUploadFiles.push(file.nome)
      })
    },


    imprimir() {

      this.$http.get('odontodocs/pedidos/imprimir/'+this.pedidoId).then( (res) => {
        this.pedidoOs = res.data
        if (this.pedidoOs !== '') {

          setTimeout(() => {
            this.$htmlToPaper('pedidoImprimir', {
              name: 'Relatório Pedidos',
              specs: [
                'fullscreen=yes',
                'titlebar=yes',
                'scrollbars=yes'
              ],
              styles: [
                'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
                'https://unpkg.com/kidlat-css/css/kidlat.css'
              ]
            })
          }, 500)
        }
      })

    },
  },
  filters: {
    converteData(data){
      if(!data) return
      return moment(data).format('DD/MM/YYYY')
    },
    converteDataHora(data){
      if(!data) return
      return moment(data).format('DD/MM/YYYY H:mm')
    },
    convertToMb(size) {
      return (size / 1048576).toFixed(2)
    }
  }
}

</script>

<style>


</style>

