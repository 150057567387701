export const arraySuperiores = ['3', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', '14'];
export const arrayInferiores = ['30', 'T', 'S', 'R', 'Q', 'P', 'O', 'N', 'M', 'L', 'K', '19']

export const superiores = [
    {
        image: 'dente-16.png',
        imageSelected: 'dente-17-selecionado.png',
        name: '3',
        number: '3',
        size: 26,
        selected: false
    },
    {
        image: 'dente-17.png',
        imageSelected: 'dente-17-selecionado.png',
        name: 'A',
        number: 'A',
        size: 26,
        selected: false
    },
    {
        image: 'dente-16.png',
        imageSelected: 'dente-16-selecionado.png',
        name: 'B',
        number: 'B',
        size: 28,
        selected: false
    },
    {
        image: 'dente-15.png',
        imageSelected: 'dente-15-selecionado.png',
        name: 'C',
        number: 'C',
        size: 18,
        selected: false
    },
    {
        image: 'dente-12.png',
        imageSelected: 'dente-12-selecionado.png',
        name: 'D',
        number: 'D',
        size: 18,
        selected: false
    },
    {
        image: 'dente-11.png',
        imageSelected: 'dente-11-selecionado.png',
        name: 'E',
        number: 'E',
        size: 20,
        selected: false
    },
    {
        image: 'dente-21.png',
        imageSelected: 'dente-21-selecionado.png',
        name: 'F',
        number: 'F',
        size: 20,
        selected: false
    },
    {
        image: 'dente-22.png',
        imageSelected: 'dente-22-selecionado.png',
        name: 'G',
        number: 'G',
        size: 18,
        selected: false
    },
    {
        image: 'dente-25.png',
        imageSelected: 'dente-25-selecionado.png',
        name: 'H',
        number: 'H',
        size: 20,
        selected: false
    },
    {
        image: 'dente-26.png',
        imageSelected: 'dente-26-selecionado.png',
        name: 'I',
        number: 'I',
        size: 24,
        selected: false
    },
    {
        image: 'dente-27.png',
        imageSelected: 'dente-27-selecionado.png',
        name: 'J',
        number: 'J',
        size: 28,
        selected: false
    },
    {
        image: 'dente-26.png',
        imageSelected: 'dente-27-selecionado.png',
        name: '14',
        number: '14',
        size: 24,
        selected: false
    },
];

export const inferiores = [
    {
        image: 'dente-46.png',
        imageSelected: 'dente-47-selecionado.png',
        name: '30',
        number: '30',
        size: 28,
        selected: false
    },
    {
        image: 'dente-47.png',
        imageSelected: 'dente-47-selecionado.png',
        name: 'T',
        number: 'T',
        size: 25,
        selected: false
    },
    {
        image: 'dente-46.png',
        imageSelected: 'dente-46-selecionado.png',
        name: 'S',
        number: 'S',
        size: 28,
        selected: false
    },
    {
        image: 'dente-45.png',
        imageSelected: 'dente-45-selecionado.png',
        name: 'R',
        number: 'R',
        size: 20,
        selected: false
    },
    {
        image: 'dente-42.png',
        imageSelected: 'dente-42-selecionado.png',
        name: 'Q',
        number: 'Q',
        size: 16,
        selected: false
    },
    {
        image: 'dente-41.png',
        imageSelected: 'dente-41-selecionado.png',
        name: 'P',
        number: 'P',
        size: 15,
        selected: false
    },
    {
        image: 'dente-31.png',
        imageSelected: 'dente-31-selecionado.png',
        name: 'O',
        number: 'O',
        size: 15,
        selected: false
    },
    {
        image: 'dente-32.png',
        imageSelected: 'dente-32-selecionado.png',
        name: 'N',
        number: 'N',
        size: 16,
        selected: false
    },
    {
        image: 'dente-35.png',
        imageSelected: 'dente-35-selecionado.png',
        name: 'M',
        number: 'M',
        size: 20,
        selected: false
    },

    {
        image: 'dente-36.png',
        imageSelected: 'dente-36-selecionado.png',
        name: 'L',
        number: 'L',
        size: 28,
        selected: false
    },
    {
        image: 'dente-37.png',
        imageSelected: 'dente-37-selecionado.png',
        name: 'K',
        number: 'K',
        size: 29,
        selected: false
    },
    {
        image: 'dente-36.png',
        imageSelected: 'dente-37-selecionado.png',
        name: '19',
        number: '19',
        size: 28,
        selected: false
    },
];
