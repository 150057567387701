<template>
  <div class="text-center mb-3 mt-1">
    <span><strong :class="signatureData ? 'text-success' : 'text-primary' ">Signature Required*</strong></span>
    <canvas
      ref="signatureCanvas"
      style="width: 100%; height: 125px;color: #0c67c3"
      :style="signatureData ? 'border: 2px solid #05a74e;' : 'border: 2px solid #0679fd;'"
    />
    
    <b-row>
      <b-col>
        <b-button size="sm" variant="outline-secondary" block @click="clearSignature">{{ $t('Limpar') }}</b-button>
      </b-col>

      <b-col>
        <b-button size="sm" variant="outline-primary" block @click="saveSignature">{{  $t('salvar_assinatura') }}</b-button>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import SignaturePad from 'signature_pad';
import {BButton, BCol, BRow} from "bootstrap-vue";

export default {
  name: 'SignatureField',
  components: {BCol, BRow, BButton},
  data() {
    return {
      signaturePad: null,
      signatureData: ''
    };
  },
  mounted() {
    const canvas = this.$refs.signatureCanvas;
    this.adjustCanvas(canvas);
    this.signaturePad = new SignaturePad(canvas);
    
    window.addEventListener('resize', this.onResize);
    
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  
  methods: {
    
    onResize() {
      const canvas = this.$refs.signatureCanvas;
      this.adjustCanvas(canvas);
      this.signaturePad.clear();
    },
    adjustCanvas(canvas) {
      const ratio = window.devicePixelRatio || 1;
      const rect = canvas.getBoundingClientRect();
      canvas.width = rect.width * ratio;
      canvas.height = rect.height * ratio;
      canvas.getContext("2d").scale(ratio, ratio);
    },

    clearSignature() {
      this.signaturePad.clear();
      this.signatureData = '';
      this.$emit('signature-saved', this.signatureData);
    },
    saveSignature() {
      if (this.signaturePad.isEmpty()) {
        alert('A assinatura está vazia!');
      } else {
        this.signatureData = this.signaturePad.toDataURL();
        this.$emit('signature-saved', this.signatureData);
      }
    }
  }
};
</script>

<style scoped>
canvas {
  touch-action: none;
}
</style>
